import { useState, useCallback, useEffect } from 'react';
import { message, Button, Row, Col, Steps, Card, Select, Divider, Checkbox } from 'antd';
import { read, utils, writeFile } from 'xlsx'
import { useDropzone } from 'react-dropzone';

const SimpleSplitter = () => {

  const [currentStep, setCurrentStep] = useState(0);
  const [fileName, setFileName] = useState();
  const [isProcessing, setIsProcessing] = useState(false);

  const [sheetJson, setSheetJson] = useState<any[]>([]);

  const [header, setHeader] = useState<any[]>([]);
  const [splitKey, setSplitKey] = useState("");
  const [sumField, setSumField] = useState<any[]>([]);
  const [titleFlag, setTitleFlag] = useState(false);

  const onDrop = useCallback(([file]: any) => {
    message.info(`文件开始读取，${file.name} ...`)
    setFileName(file.name)
    var reader = new FileReader();
    reader.onload = (e: any) => {
      var data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      const sheet = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { raw: true, defval: null }) as any
      setSheetJson(sheet)
      const sheetHeader = Object.keys(sheet[0])
      setHeader(sheetHeader)
      message.info(`文件成功读取，共${sheet.length}行${sheetHeader.length}列`)
      setCurrentStep(1)
    };
    reader.readAsArrayBuffer(file);
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

  useEffect(() => {
    console.log('debug4', currentStep, isProcessing)
    if (isProcessing) {
      var sheetKeyMap = {} as any
      var rowKeyMap = {} as any
      sheetJson.forEach((row: any, index: number) => {
        const sheetKey = row[splitKey]
        const rowKey = row[splitKey]
        if (!sheetKeyMap[sheetKey]) sheetKeyMap[sheetKey] = []
        if (!sheetKeyMap[sheetKey].includes(rowKey)) sheetKeyMap[sheetKey].push(rowKey)
        if (!rowKeyMap[rowKey]) rowKeyMap[rowKey] = []
        rowKeyMap[rowKey].push(index)
      })

      var outputWorkbook = utils.book_new();
      Object.keys(sheetKeyMap).forEach(sheetKey => {
        var rows = [] as any

        sheetKeyMap[sheetKey].forEach((rowKey: any) => {
          rows.push(...rowKeyMap[rowKey].map((index: number) => sheetJson[index]))
        })

        // extra summary row if there is any
        if (sumField && sumField.length > 0) {
          var sumRow = {} as any
          sumField.forEach((field: any) => sumRow[field] = 0)
          for (var i = 0; i < rows.length; i++) {
            sumField.forEach((field: any) => sumRow[field] += rows[i][field] || 0)
          }
          rows.push(sumRow)
        }

        const outputSheet = utils.json_to_sheet(rows, { header, origin: titleFlag ? 'A2' : 'A1' } as any)

        // extra title row if there is any
        if (titleFlag) {
          utils.sheet_add_json(outputSheet, [{ 0: `${splitKey}: ${sheetKey}` }], { skipHeader: true, origin: 'A1' })
        }

        utils.book_append_sheet(outputWorkbook, outputSheet, sheetKey)
      })

      message.success('结果文件即将被下载')

      writeFile(outputWorkbook, `AT-${fileName}`);
      setIsProcessing(false)
    }
  }, [isProcessing])

  const onProcessing = () => {
    console.log('debug1', currentStep, isProcessing)
    setCurrentStep(2)
    console.log('debug2', currentStep, isProcessing)
    setIsProcessing(true)
    console.log('debug3', currentStep, isProcessing)
    message.info('准备生成结果文件')
  }

  console.log('refresh', currentStep, isProcessing)
  return (
    <>
      <Divider>文档拆分工具</Divider>
      <Row gutter={[6, 24]}>
        <Col span={24}>
          <Steps type="navigation" size="small" current={currentStep}>
            <Steps.Step title="上传" />
            <Steps.Step title="设置" />
            <Steps.Step title="下载" />
          </Steps>
        </Col>
        <Col span={24}>
          <div style={{ borderStyle: 'dashed', padding: '6px' }} {...getRootProps()}>
            <input {...getInputProps()} />
            {
              fileName ?
                <>
                  <p>已上传：{fileName}</p>
                  <p>设置好拆分词后便可进行文件拆分</p>
                </>
                :
                <>
                  <p>点击这里或拖拽文件到这里进行上传</p>
                  <p><b>注意：该文件只能包含一个工作表（sheet），且首行（row）必须为标题行（header）</b></p>
                </>
            }
          </div>
        </Col>
        <Col span={24}>
          <Select
            options={header.map(header => ({ key: header, value: header, label: header }))}
            value={splitKey} onChange={setSplitKey} style={{ width: '100%', margin: '6px 0' }}
            placeholder={"设置拆分词"} disabled={header.length === 0}
          />
          <Select
            options={header.map(header => ({ key: header, value: header, label: header }))}
            value={sumField} onChange={setSumField} style={{ width: '100%', margin: '6px 0' }}
            mode="multiple"
            placeholder={"设置统计列（可选）"} disabled={header.length === 0}
          />
          <Checkbox
            checked={titleFlag} onChange={(e: any) => setTitleFlag(e.target.checked)}
            style={{ width: '100%', margin: '6px 0' }}>
            生成拆分词标题行（可选）
            </Checkbox>
        </Col>
        <Col span={24}>
          <Button onClick={onProcessing} disabled={!splitKey} loading={isProcessing} type="primary" style={{ width: '100%' }}>
            拆分 & 下载
          </Button>
        </Col>
      </Row>
    </>
  )


}

export default SimpleSplitter