import React, { useState, useCallback, useEffect } from 'react';
import { message, Button, Row, Col, Steps, Divider, InputNumber, Input } from 'antd';
import { read, utils, writeFile } from 'xlsx'
import { useDropzone } from 'react-dropzone';

const SimpleMerger = () => {

  const [currentStep, setCurrentStep] = useState(0);
  const [fileName, setFileName] = useState();
  const [isProcessing, setIsProcessing] = useState(false);

  const [workbook, setWorkbook] = useState<any>();

  const [headerRowIndex, setHeaderRowIndex] = useState<number>(0); // zero-based index
  const [sheetKeyName, setSheetKeyName] = useState<string>("工作表名称");

  const onDrop = useCallback(([file]: any) => {
    message.info(`文件开始读取，${file.name} ...`)
    setFileName(file.name)
    var reader = new FileReader();
    reader.onload = (e: any) => {
      var data = new Uint8Array(e.target.result);
      const wb = read(data, { type: 'array' });
      setWorkbook(wb)
      message.info(`文件成功读取，共${wb.SheetNames.length}个工作表`)
      setCurrentStep(1)
    };
    reader.readAsArrayBuffer(file);
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

  useEffect(() => {
    if (isProcessing) {
      var rows = [] as any
      workbook.SheetNames.forEach((sheetName: any) => {
        const sheet = workbook.Sheets[sheetName]
        const sheetJson = utils.sheet_to_json(sheet, { header: 'A', raw: false, blankrows: true }) as any
        const header = sheetJson[headerRowIndex] as any
        for (var i = headerRowIndex + 1; i < sheetJson.length; i++) {
          var row = { [sheetKeyName]: sheetName } as any
          Object.keys(header).forEach(cellKey => {
            row[header[cellKey].trim()] = sheetJson[i][cellKey]
          })
          rows.push(row)
        }
      })
      const sumSheet = utils.json_to_sheet(rows)

      var outputWorkbook = utils.book_new();
      utils.book_append_sheet(outputWorkbook, sumSheet, '合并')

      message.success('结果文件即将被下载')
      writeFile(outputWorkbook, `AT-${fileName}`);
      setIsProcessing(false)
    }
  }, [isProcessing])

  const onProcessing = () => {
    setCurrentStep(2)
    setIsProcessing(true)
    message.info('准备生成结果文件')
  }

  return (
    <>
      <Divider>文档合并工具</Divider>
      <Row gutter={[6, 24]}>
        <Col span={24}>
          <Steps type="navigation" size="small" current={currentStep}>
            <Steps.Step title="上传" />
            <Steps.Step title="设置" />
            <Steps.Step title="下载" />
          </Steps>
        </Col>
        <Col span={24}>
          <div style={{ borderStyle: 'dashed', padding: '6px' }} {...getRootProps()}>
            <input {...getInputProps()} />
            {
              fileName ?
                <>
                  <p>已上传：{fileName}</p>
                  <p>可进行文件合并</p>
                </>
                :
                <>
                  <p>点击这里或拖拽文件到这里进行上传</p>
                  <p><b>注意：该文件只能包含用于合并的工作表（sheet），且每个工作表的标题行（header）必须在同一行</b></p>
                </>
            }
          </div>
        </Col>
        <Col span={24}>
          <InputNumber
            value={headerRowIndex + 1} onChange={(v: any) => setHeaderRowIndex(v - 1)} style={{ width: '100%', margin: '6px 0' }}
            placeholder={'标题行所在行数（默认为首行）'} min={1} disabled={!workbook}
          />
          <Input
            value={sheetKeyName} onChange={(e: any) => setSheetKeyName(e.target.value)} style={{ width: '100%', margin: '6px 0' }}
            placeholder={'新增识别列的标题（默认为“工作表名称”）'} disabled={!workbook}
          />
        </Col>
        <Col span={24}>
          <Button onClick={onProcessing} disabled={!workbook || !headerRowIndex || !sheetKeyName} loading={isProcessing} type="primary" style={{ width: '100%' }}>
            合并 & 下载
          </Button>
        </Col>
      </Row>
    </>
  )


}

export default SimpleMerger