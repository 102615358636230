import React from 'react';
import { Card, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import SimpleSplitter from './component/SimpleSplitter';
import SimpleMerger from './component/SimpleMerger';

function App() {

  return (
    <Row gutter={[12, 12]} style={{ margin: '6px', maxWidth: '1200px' }}>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Card bordered={false} >
          <SimpleSplitter />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Card bordered={false} >
          <SimpleMerger />
        </Card>
      </Col>
    </Row>
  )
}

export default App;
